.dealers-container {
  align-self: center;
  max-width: 1444px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 30px 60px;
  gap: 100px;

  @include e(one) {
    margin-bottom: -50px;
    align-self: center;
  }
  @include e(two) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include e(text) {
      max-width: 60%;
    }
    @include e(image) {
      margin-top: -6%;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  @include e(three) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include e(content) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      & img {
        width: auto;
        height: 100%;
      }
    }
  }
  @include e(four) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include e(text) {
      max-width: 60%;
    }
    @include e(image) {
      margin-top: -3%;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  @include e(five) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    @include e(text) {
      max-width: 60%;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1000px) {
  .dealers-container {
    @include e(two) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }

      @include e(image) {
        margin-top: 0%;
        align-self: center;
      }
    }
    @include e(three) {
      @include e(content) {
        align-self: flex-start;
        & img {
          width: 120px;
        }
      }
    }
    @include e(four) {
      flex-direction: column-reverse;

      @include e(text) {
        max-width: 100%;
      }
      @include e(image) {
        margin-top: 0%;
        align-self: center;
      }
    }
    @include e(five) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }
      @include e(image) {
        align-self: center;
      }
    }
  }
}
@media (max-width: 500px) {
  .dealers-container {
    padding: 20px 20px;
    width: 100%;
    gap: 50px;
    @include e(one) {
      margin-bottom: -30px;
    }
  }
}
