.privacy-notice {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  gap: 20px;
}

.company-image {
  display: flex;
  align-items: center;
  align-self: center;
  & img {
    cursor: pointer;
  }
}
.page-footer {
  font-weight: bold;
  font-size: 12px;
  color: #666;
}

.clearfix:before,
.clearfix:after {
  content: "";
  clear: both;
  zoom: 1;
}

body {
  color: #4f4c4c;
  font-size: 18px;
  font-family: "Rift-Regular";
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.align_center {
  text-align: center;
}

.heading {
  font-family: "Rift-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #000;
}

.font-big {
  font-family: "Rift-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #000;
}
.page-wrap {
  max-width: 950px;
  padding: 0px 10px 0px 10px;
  margin: 0 auto;
  font-family: "Rift-Regular";
  font-size: 18px;
}

.page-head {
  text-align: center;
  margin-top: 20px;
}

.fact {
  margin-top: 20px;
  width: 100%;
  border: 2px solid #000;
  line-height: 2;
  overflow: hidden;
}

.pp-wrap {
  width: 100%;
  margin: 5px 0;
  line-height: 2;
  clear: both;
  border: 2px solid #000;
  background: gray;
}

.fact_text {
  float: left;
  width: 20%;
  padding-left: 20px;
  text-align: left;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 1.4px;
  color: #fff;
  border-bottom: 3px solid #000;
  background: #000;
}

.fact_desp {
  display: inline-block;
  color: #000;
}

.fact_block {
  width: 100%;
  overflow: hidden;
  clear: both;
  height: auto;
}

.fact_block_left {
  float: left;
  width: 20%;
  color: #fff;
  text-align: left;
  font-size: 23px;
  font-weight: 600;
  padding-left: 20px;
  line-height: 1.4;
  text-transform: uppercase;
}

.fact_block_right {
  width: 80%;
  float: left;
  text-align: justify;
  padding: 5px;
  line-height: 1.2;
  background: #fff;
  border-left: 2px solid #000;
}

.table {
  width: 100%;
  margin-bottom: 5px;
  border: none;
  border-collapse: collapse;
  border: 2px solid #000;
}

.table tr {
  padding: 10px;
  border: 1px solid #bdbcbc;
}
.table td {
  padding: 10px;
  padding-left: 25px;
  border: 1px solid #bdbcbc;
}
ul {
  list-style-type: square;
}
.table li {
  align-items: center;
  margin-left: 50px;
}
.table h4 {
  line-height: 1.2;
  letter-spacing: 0.1px;
  font-weight: bold;
  font-size: 18px;
  clear: both;
}

.details_head {
  background: gray;
  font-size: 18px;
  color: #fff;
  border: 0;
}

.details_head_td {
  padding: 5px;
}

// css for lower window sizes//
@media (max-width: 900px) {
  .privacy-notice {
    padding: 50px 0;

    @include e(content) {
      gap: 20px;
      width: 90%;

      @include e(text) {
        border-radius: 10px;
        padding: 10px 20px;
      }
    }
  }

  .fact_text {
    width: 100%;
  }
  .fact_block_left {
    padding-left: 10px;
    width: 100%;
  }
  .fact_block_right {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .privacy-notice {
    padding: 40px 0px;

    @include e(content) {
      gap: 10px;
      width: 95%;

      @include e(text) {
        padding: 0px 0px;
      }
    }
  }
}
@media (max-width: 350px) {
  .privacy-notice {
    padding: 20px 0px;

    @include e(content) {
      gap: 10px;
      width: 100%;

      @include e(text) {
        padding: 0px 0px;
      }
    }
  }
  .page-wrap {
    max-width: 950px;
    padding: 0px 5px 0px 5px;
    margin: 0 0;
    font-family: "Rift-Regular";
    font-size: 18px;
  }
}

// css for Privacy policy page
.privacy-policy-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: "Noto Sans";
  font-style: normal;

  .privacy-policy {
    align-self: center;
    max-width: 1444px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 60px;

    u {
      text-decoration-skip-ink: none;
    }

    p,
    strong {
      margin-right: 5px;
    }
    a,
    u {
      margin: 0 5px;
    }

    table {
      border-collapse: collapse;

      td {
        padding: 10px;
      }
    }
    li {
      margin-left: 30px;
    }

    .ordered-lists {
      ol {
        counter-reset: section;
        list-style-type: none;
        li:before {
          counter-increment: section;
          content: counters(section, ".") ". ";
        }
      }
      ul {
        list-style-type: circle;
        color: black;
        li:before {
          content: none;
        }
        li {
          margin-bottom: 5px;
        }
      }
    }
    .space-50 {
      padding-left: 50px;
    }
  }
}

@media (max-width: 500px) {
  .privacy-policy {
    padding: 20px 20px !important;
    li {
      margin-left: 10px !important;
    }
  }
  .space-50 {
    padding-left: 10px !important;
  }
}
