.sign-up-today-btn {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  cursor: pointer;
  color: #ffffff;
  background: rgba(234, 45, 45, 0.76);
  border: 1px solid #c20606;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 10px 20px;
}

.circle-image {
  max-width: 190px;
  max-height: 190px;
}

@media (max-width: 1200px) {
  .access-image {
    max-width: 400px;
  }
}

@media (max-width: 1000px) {
  .circle-image {
    max-width: 120px;
  }
}

@media (max-width: 500px) {
  .circle-image {
    max-width: 55px;
    max-height: 55px;
  }
  .access-image {
    max-width: 100%;
  }
  .sign-up-today-btn {
    font-size: 14px;
    padding: 6px 16px;
  }
}

button {
  cursor: pointer;
}

.hover-button:hover,
.hover-button:focus {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  transform: translateY(-0.1em);
}
