.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 120px;
  padding-bottom: 20px;
  @include e(menu) {
    & button {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
      font-size: 22px;
      line-height: 150%;
      letter-spacing: -0.011em;
      background: none;
      border: none;
      position: relative;
      padding-bottom: 2px;

      &:hover {
        color: #ff0000;

        &::before {
          content: "";
          height: 2px;
          background: #ff0000;
          display: block;
          width: 50px;
          border-radius: 10px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @include m(active) {
      & button {
        font-style: normal;
        font-weight: 700;
        color: #ff0000;
        position: relative;
        &::before {
          content: "";
          height: 3px;
          background: #ff0000;
          display: block;
          width: 50px;
          border-radius: 10px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.dropdown-active {
  & button {
    font-style: normal;
    font-weight: 700;
    color: #ff0000;
    position: relative;

    &::before {
      content: "";
      height: 3px;
      background: #ff0000;
      display: block;
      width: 50px;
      border-radius: 10px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: url("../../images/drop.svg");
      display: block;
      position: absolute;
      bottom: -36px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1150px) {
  .navigation {
    padding-left: 100px;
    @include e(menu) {
      & button {
        font-size: 18px;
        line-height: 100%;
        padding-bottom: 5px;
      }
    }
  }
  .dropdown-active {
    & button {
      &::after {
        bottom: -30px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .navigation {
    padding-left: 80px;
    @include e(menu) {
      & button {
        font-size: 15px;
        line-height: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .navigation {
    flex-direction: column;
    white-space: nowrap;
    padding-left: 15px;
    height: 50%;
    justify-content: space-between;

    padding-top: 20px;
    @include e(menu) {
      margin-right: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f29320;
      & button {
        color: #767676;
      }
    }
  }
}
