.footer-section {
  background: #00497b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
  align-self: flex-end;
  white-space: nowrap;
  font-family: "Noto Sans";
  font-style: normal;

  & button {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    background: none;
    border: none;
    cursor: pointer;
    letter-spacing: 0.06em;
    color: #ffffff;
  }

  .company-address,
  .copyright {
    & a {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 1.2px;
      text-decoration-line: underline;
    }
    & p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 1.2px;
    }
  }
  .socials {
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-width: fit-content;
    & img {
      max-width: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .footer-section {
    white-space: normal;
    padding: 20px 30px;
    gap: 10px;
    & button {
      font-size: 16px;
    }
    .company-address,
    .copyright {
      & a,
      p {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 720px) {
  .footer-section {
    white-space: normal;
    padding: 20px 20px;
    gap: 10px;
    & button {
      font-size: 14px;
    }
    .company-address,
    .copyright {
      & a,
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 500px) {
  .footer-section {
    display: none;
  }
}
