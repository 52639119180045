.header-wrapper {
  display: flex;
  flex-direction: column;
  height: 200px;
  z-index: 2;
}
.header {
  background: #e9f6fc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
}
.certificate {
  margin: 0 30px 30px 30px;
  width: max-content;
  & img {
    mix-blend-mode: darken;
    max-width: 100px;
  }
}

.header-contains {
  padding-left: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include e(left) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    margin-top: -10px;
    &img {
      width: auto;
    }

    @include e(text) {
      display: flex;
      flex-direction: row;
      text-align: center;
      align-self: center;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
    }
    .first-help {
      font-family: "PT Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 55px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #226ab2;
    }
    .financial {
      font-family: "PT Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 55px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ff0000;
    }
  }

  @include e(top-right) {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: -20px;
    gap: 40px;
    align-self: flex-end;
    @include e(contacts) {
      display: flex;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      gap: 40px;
      letter-spacing: -0.011em;

      & a {
        color: #00497b;
        text-decoration: none;
      }
      @include e(email) {
        display: flex;
        flex-direction: row;
        align-items: center;

        & a {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        @include e(icon) {
          padding-right: 8px;
        }
      }
      @include e(phone) {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;

        & a {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        @include e(icon) {
          padding-right: 8px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .header-contains {
    @include e(top-right) {
      gap: 30px;
      @include e(contacts) {
        gap: 10px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .certificate {
    margin: 0 20px 20px 20px;
  }
  .header-contains {
    padding-left: 20px;
    @include e(top-right) {
      gap: 20px;
    }
  }
}
.header-above-500px {
  height: 200px;
  z-index: 2;
}
.header-below-500px {
  display: none;
}

@media (max-width: 500px) {
  .header-below-500px {
    display: block;
  }
  .header-above-500px {
    display: none;
  }
}
