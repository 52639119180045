.metrics-page-contents {
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans";
  font-style: normal;
  header {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    padding: 24px 60px;
    width: 100%;
    position: fixed;
  }

  a {
    text-decoration: none;
  }

  h2 {
    padding-top: 72px;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    color: #011423;
  }

  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
}

.logo-image {
  width: 100%;
}

.font-black {
  font-size: 20px;
  line-height: 28px;
  color: #011423;
}
.metrics-footer {
  margin-top: 10px;
}

/* css for metrics page */

.content {
  display: flex;
  flex-direction: column;
  padding: 24px 96px;
  margin-top: 150px;
  background: #ffffff;
  gap: 20px;
  justify-content: space-between;
}

.metrics-table {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-self: center;
}

.metrics-table p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  padding: 6px 10px;
  color: black;
}

.empty {
  height: 30px;
  padding: 6px 10px;
  line-height: 21px;
}

.table-header {
  font-weight: 400;
  line-height: 21px;
  max-height: 30px;
  padding: 6px 10px;
  font-size: 20px;
  color: #ffffff;
  background: #e0202c;
  white-space: nowrap;
}

.column-3 p {
  white-space: nowrap;
}
.column-4 p {
  text-align: right;
}
.column-1,
.column-2,
.column-3,
.column-4 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
}

.column-2 p,
.column-3 p,
.column-4 p {
  background: rgb(231, 225, 225);
}

.column-1 p {
  color: white;
}

.box-size-big {
  min-height: 109px;
}

.box-size-medium {
  min-height: 71px;
  max-height: 71px;
}

.bg-black {
  background: rgb(34, 32, 32);
}

.bg-blue {
  background: rgb(89, 89, 236);
}

.bg-light-blue {
  background: rgb(167, 175, 247);
}

.bg-dark-blue {
  background: rgb(8, 8, 218);
}

@media (max-width: 1200px) {
  .metrics-table p {
    font-size: 16px;
    line-height: 21px;
    padding: 6px 10px;
  }
}
@media (max-width: 1000px) {
  .metrics-table p {
    font-size: 15px;
    line-height: 21px;
    padding: 6px 10px;
  }

  .table-header {
    font-size: 18px;
    white-space: nowrap;
  }
}
@media (max-width: 900px) {
  .content {
    padding: 24px 36px;
  }

  .metrics-table p {
    font-size: 12px;
    line-height: 21px;
    height: 60px;
    padding: 6px 10px;
  }
  .column-3 p {
    white-space: normal;
  }

  .table-header {
    font-size: 15px;
    white-space: nowrap;
  }
  .box-size-big {
    min-height: 190px;
  }
  .box-size-medium {
    min-height: 125px;
  }
}
@media (max-width: 600px) {
  .table-header {
    white-space: normal;
    min-height: 60px;
  }
  .empty {
    min-height: 60px;
  }
  .metrics-table p {
    height: 100px;
  }
  .box-size-big {
    min-height: 310px;
  }

  .box-size-medium {
    min-height: 205px;
  }
}

@media (max-width: 360px) {
  .logo-image {
    width: 250px;
    margin: 6px 0px;
  }
}
