.payments {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  gap: 40px;
  z-index: 3;
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);

  @include e(top-section) {
    & h1 {
      font-family: "PT Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 145%;
      letter-spacing: 0.02em;
      color: #00497b;
    }
    & p {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: -0.011em;
      color: #000000;
    }
  }
  @include e(middle-section) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;

    @include e(left) {
      width: 70%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 80px;
      justify-content: space-between;

      @include e(contents) {
        font-family: "Noto Sans";
        font-style: normal;
        line-height: 150%;
        letter-spacing: -0.011em;
        display: flex;
        flex-direction: column;
        gap: 5px;
        & h4 {
          font-weight: 400;
          font-size: 22px;
          color: #00497b;
          cursor: pointer;
        }
        & p {
          font-weight: 300;
          font-size: 20px;
          color: #000000;
          display: inline-block;
        }
      }
    }

    @include e(right) {
      margin: auto;
      width: 30%;
      @include e(contents) {
        display: flex;
        flex-direction: column;

        gap: 5px;

        & h4 {
          font-family: "PT Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 145%;
          letter-spacing: 0.02em;
          color: #f18400;
        }
        & p {
          font-family: "Noto Sans";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: -0.011em;
          color: #000000;
        }
      }
    }
  }
  @include e(bottom-section) {
    align-self: flex-end;
    .button-close {
      background: linear-gradient(
        180deg,
        #f18a0c 0%,
        rgba(232, 171, 97, 0.99) 55.73%,
        rgba(241, 138, 12, 0.9) 100%
      );
      border: 1px solid #f29320;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      padding: 10px 40px;
      color: #ffffff;
    }
  }
}

.line {
  border: 1px dashed #767676;
  height: 400px;
  margin: auto;
}
.addresses {
  font-family: "Noto Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.011em;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;

  & h3 {
    font-weight: 600;
    font-size: 20px;
    color: #00497b;
  }
  .address-text {
    font-weight: 500;
  }
}
.downlaod-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-around;
  gap: 20px;
}
.pay-now-button {
  display: flex;
  align-self: center;
  & button {
    cursor: pointer;
    border-radius: 30px;
    padding: 10px 70px;
    width: max-content;
    background: linear-gradient(
      180deg,
      #0cf168 0%,
      rgba(116, 232, 97, 0.99) 55.73%,
      rgba(58, 241, 12, 0.9) 100%
    );
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.active-header {
  & h4 {
    font-weight: 600;
    color: #f18400;
  }
}
.alert {
  font-weight: 300;
  color: #ff0000 !important;
}
.bold-blue {
  font-weight: 600 !important;
  color: #00497b !important;
}
@media (max-width: 500px) {
  .payments {
    display: none;
  }
  .pay-now-button {
    margin: auto;
    margin-top: 10px;
    padding: 8px 40px;
    & button {
      font-size: 14px;
    }
  }
}

.payments-mobile {
  @include e(container) {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    @include e(content) {
      border-radius: 10px;
      padding: 10px 15px;

      .addresses {
        margin-top: 0px;
        gap: 0px;
      }
      h1 {
        color: #00497b;
        font-family: Noto Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.242px;
      }
      h2 {
        color: #000;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: -0.154px;
        padding-bottom: 10px;
      }
      p {
        color: #000;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: -0.154px;
      }
    }
  }
}

.easy-pay {
  background: #fdda92;
  @include e(content) {
    border-radius: 10px;
    background: #fff8de;
    padding: 10px 15px;
  }
}
.debit-card {
  background: #7bd1df;
  @include e(content) {
    border-radius: 10px;
    background: #def3ff;
    padding: 10px 15px;
  }
}
.money-by-phone {
  background: #f2a28c;
  @include e(content) {
    border-radius: 10px;
    padding: 10px 15px;
    background: #ffe8de;
  }
}
.money-by-mail {
  background: #bcc8f2;
  @include e(content) {
    border-radius: 10px;
    padding: 10px 15px;
    background: #deefff;
    h3 {
      font-size: 14px;
    }
  }
}
.moneygram {
  background: #bae8a4;
  @include e(content) {
    border-radius: 10px;
    padding: 10px 15px;
    background: #f2ffde;
  }
}
.csv {
  background: #ff6d6d;
  @include e(content) {
    border-radius: 10px;
    padding: 10px 15px;
    background: #fcb4b4;
  }
}

@media (min-width: 1024px) {
  .payments-mobile {
    @include e(container) {
      padding: 40px 40px;
      @include e(content) {
        h1 {
          font-size: 30px;
        }
        h2,
        p {
          font-size: 20px;
        }
      }
    }
    .pay-now-button {
      justify-content: center;
    }
  }
}
