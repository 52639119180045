.language {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;

  @include e(link) {
    & button {
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.011em;
      border: none;
      background: none;
      color: #625a5a;
      position: relative;

      &:hover {
        color: #ff0000;
        cursor: pointer;

        &::before {
          content: "";
          height: 2px;
          background: #ff0000;
          display: block;
          width: 20px;
          border-radius: 10px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @include m(active) {
      & button {
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.011em;
        border: none;
        background: none;
        color: #ff0000;
        position: relative;
        &::before {
          content: "";
          height: 2px;
          background: #ff0000;
          display: block;
          width: 20px;
          border-radius: 10px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .language {
    width: 80%;

    @include e(link) {
      & button {
        font-size: 16px;
      }
      @include m(active) {
        & button {
          font-size: 16px;
        }
      }
    }
  }
}
