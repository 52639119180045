.contacts {
  background: #ffffff;
}
.contact-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  white-space: nowrap;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  letter-spacing: 0.06em;
  color: #00497b;

  & a {
    color: #00497b;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  @include e(email) {
    text-decoration: underline;
    @include e(icon) {
      padding-right: 5px;
    }
  }
  @include e(phone) {
    @include e(icon) {
      padding-right: 5px;
    }
  }
  @include e(whatsapp) {
    @include e(icon) {
      padding-right: 5px;
    }
  }
}

.W500 {
  display: none;
}
@media (max-width: 1200px) {
  .contact-section {
    padding: 10px 20px;
  }
  .contact-text {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-section {
    justify-content: space-between;
    padding: 5px 10px;
    gap: 10px;
    font-size: 16px;
    & img {
      max-width: 30px;
    }
    & a {
      gap: 0px;
    }
  }
  .contact-text {
    font-size: 16px;
  }
}
@media (max-width: 650px) {
  .contact-section {
    justify-content: space-between;
    gap: 2px;
    & img {
      max-width: 25px;
    }
  }
  .contact-text {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .W500 {
    display: block;
  }
  .not500 {
    display: none;
  }
  .contacts {
    background: #00497b;
  }
  .contact-section {
    width: 100%;
    padding: 10px 20px;
  }
  .contact-text {
    display: none;
  }
}
