.about-us {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.about-us-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 60px;
  gap: 100px;

  @include e(one) {
    align-self: center;
  }
  @include e(two) {
    display: flex;
    flex-direction: row;
    gap: 180px;
  }
  @include e(three) {
    display: flex;
    flex-direction: row;
    gap: 180px;
  }
  @include e(four) {
    display: flex;
    flex-direction: row;
    gap: 180px;
  }
  @include e(five) {
    display: flex;
    flex-direction: row;
    gap: 180px;
  }
  @include e(six) {
    display: flex;
    flex-direction: row;
    gap: 180px;
    padding-bottom: 50px;
  }
}
