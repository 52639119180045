.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  padding: 30px 60px;
  align-self: center;
  max-width: 1444px;

  @include e(one) {
    margin-bottom: -50px;
    align-self: center;
  }
  @include e(two) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    @include e(text) {
      max-width: 60%;
    }
    & img {
      width: 100%;
      height: 100%;
    }

    @include e(image) {
      align-self: center;
    }
  }
  @include e(three) {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    @include e(content) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      & img {
        width: 100%;
      }
    }
  }
  @include e(four) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    @include e(text) {
      max-width: 70%;
    }
    & img {
      width: 100%;
      height: 100%;
    }

    @include e(image) {
      align-self: center;
    }
  }
  @include e(five) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 50px;
    @include e(text) {
      max-width: 70%;
    }
    & img {
      width: 100%;
      height: 100%;
    }

    @include e(image) {
      align-self: center;
    }
  }
}

.text-big {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 150%;
  flex-wrap: nowrap;
  white-space: nowrap;
  letter-spacing: -0.011em;
  color: #00497b;
}
.text-mid {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #f00;
  margin-bottom: 10px;
  white-space: nowrap;
}
.text-small {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #000000;
}
.info-image {
  width: 190px;
  max-height: 190px;
}

@media (max-width: 1400px) {
  .text-mid {
    font-size: 40px;
  }
}
@media (max-width: 1300px) {
  .text-big {
    font-size: 50px;
    @include m(right) {
      margin-left: 100px;
    }
  }
  .text-mid {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .info-container {
    @include e(three) {
      @include e(content) {
        & img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .text-big {
    font-size: 45px;
    @include m(right) {
      margin-left: 70px;
    }
  }
  .text-mid {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .text-big {
    font-size: 40px;
    @include m(right) {
      margin-left: 0px;
    }
  }
  .text-mid {
    font-size: 28px;
  }
  .text-small {
    font-size: 18px;
  }

  .info-container {
    @include e(two) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }
    }
    @include e(four) {
      flex-direction: column-reverse;
      @include e(text) {
        max-width: 100%;
      }
    }
    @include e(five) {
      flex-direction: column;
      @include e(text) {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .info-container {
    flex-wrap: wrap;
    padding: 30px 20px;
    gap: 50px;

    @include e(one) {
      margin-bottom: -30px;
    }
  }
  .text-big {
    font-size: 30px;
    letter-spacing: -0.308px;
    white-space: normal;
    @include m(right) {
      margin-left: 0px;
    }
  }
  .text-mid {
    font-size: 24px;
    white-space: normal;
    letter-spacing: -0.264px;

    @include m(right) {
      margin-right: 30px;
    }
  }
  .text-small {
    font-size: 14px;
    letter-spacing: -0.154px;
  }
}
