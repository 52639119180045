.header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #e9f6fc;
  align-items: center;
  width: 100%;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
}

.checkmark span {
  width: 20px;
  height: 2px;
  background-color: #767676;
  position: absolute;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.checkmark span:nth-child(1) {
  top: 10%;
}

.checkmark span:nth-child(2) {
  top: 50%;
}

.checkmark span:nth-child(3) {
  top: 90%;
}

.container input:checked + .checkmark span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
}

.container input:checked + .checkmark span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  -webkit-transform: translateY(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) rotate(-45deg);
  -ms-transform: translateY(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) rotate(-45deg);
}

.container input:checked + .checkmark span:nth-child(3) {
  transform: translateX(-50px);
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  -o-transform: translateX(-50px);
  opacity: 0;
}

.navigation-mobile {
  z-index: 3;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 90%;
  justify-content: space-around;
  padding-bottom: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.language-mobile {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  width: 100%;
}
.certificate-small {
  margin-top: -14px;
  max-width: 80%;
  mix-blend-mode: darken;
  max-width: 35px;
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  justify-content: space-between;
  gap: 20px;
  font-family: "Noto Sans";
  font-style: normal;
  color: #767676;
  & button {
    color: #767676;
    font-size: 16px;
  }
  & a {
    font-size: 16px;
    font-weight: 600;
  }
  & p {
    font-size: 16px;
  }
}
