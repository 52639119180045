.pay-btn {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  cursor: pointer;
  color: #ffffff;
  background: rgba(234, 45, 45, 0.76);
  border: 1px solid #c20606;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 10px 20px;
}

@media (max-width: 500px) {
  .pay-btn {
    font-size: 14px;
    padding: 6px 16px;
  }
}
