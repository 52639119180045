.terms-of-service-page {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: "Noto Sans";
  font-style: normal;
}
.terms-of-service {
  align-self: center;
  max-width: 1444px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 60px;

  li {
    margin-left: 50px;
  }
  ul {
    list-style-type: circle;
  }

  p,
  strong,
  u {
    margin-right: 5px;
  }
  a {
    margin: 0 5px;
  }
}

.box {
  border: 1px solid black;
  padding: 20px;
}

@media (max-width: 500px) {
  .terms-of-service {
    padding: 20px 20px;
    li {
      margin-left: 10px;
    }
  }
}
